// extracted by mini-css-extract-plugin
export var deals_container = "specials-module--deals_container--45cc1";
export var deals_header_container = "specials-module--deals_header_container--39cd1";
export var deals_info = "specials-module--deals_info--0053e";
export var deals_info_open = "specials-module--deals_info_open--db5ee";
export var heroBtnContainer = "specials-module--heroBtnContainer--a4abc";
export var inactive = "specials-module--inactive--95b85";
export var item__top_buttons = "specials-module--item__top_buttons--38ac3";
export var no_result__desc = "specials-module--no_result__desc--10ecf";
export var no_special__header = "specials-module--no_special__header--bde76";
export var special__container = "specials-module--special__container--19964";
export var special__header = "specials-module--special__header--0e2f6";
export var special_container_block = "specials-module--special_container_block--88e15";