import React, { useEffect, useMemo, useState, memo } from "react";
import { useSelector, useStore } from "react-redux";
import { navigate } from "gatsby";
import algoliasearch from "algoliasearch/lite";
import queryString from "query-string";
import moment from "moment";
import { getBoundActions } from "../state/actions";
import ProductsPanel from "@col-care/common/blocks/product-panel";
import Modal from "../components/modal/Modal";
import Spinner from "@col-care/common/elements/spinner/Spinner";
import Button from "../components/button/Button";
import { getSlug } from "@col-care/common/helpers";
import {
  isActiveDateRange,
  isSpecialsBrandsOrProductsAvailable,
} from "@col-care/common/utilities/index";
import { posthogEvents } from "@col-care/common/utilities/constants";
import * as styles from "../styles/specials.module.css";

export default function ShopStoreSpecials(props) {
  const store = useStore();
  const actions = getBoundActions(store.dispatch);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const cartData = useSelector((state) => state.session.cartData);
  const location = useSelector((state) => state.router.location);
  const locationData = useSelector((state) => state.hydrate.locationData);
  const siteConfigData = useSelector((state) => state.hydrate.siteConfigData);
  const [apiData, setApiData] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [isActive, setActive] = useState(false);
  const [showLocationUpdateModal, setShowLocationUpdateModal] = useState(null);
  const [specialsData, setSpecialsData] = useState(null);
  const posthog = props?.posthog;

  const [showCartModal, setShowCartModal] = useState(false);

  const parsed = queryString.parse(props.location.search);

  const searchClient = algoliasearch(
    siteConfigData?.env?.jane?.application_id,
    siteConfigData?.env?.jane?.algolia_key
  );
  const janeAlgoliaIndex = "menu-products-production";

  const locations = props.pageContext.databag.locations;

  const locationsById = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  const locationsByStoreSlug = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  const segments = location.pathname ? location.pathname.split("/") : [];
  const thisStoreId = segments[2];
  const thisStore = thisStoreId ? locationsById[thisStoreId] : null;

  const toggleClass = () => {
    setActive(!isActive);
    console.info(
      `Posthog event -> ${
        isActive
          ? posthogEvents.SPECIALS_ACCORDION_CLOSED
          : posthogEvents.SPECIALS_ACCORDION_OPENED
      }`
    );
    posthog?.capture(
      isActive
        ? posthogEvents.SPECIALS_ACCORDION_CLOSED
        : posthogEvents.SPECIALS_ACCORDION_OPENED,
      {
        $EventDetails: {
          store: thisStore.content.id,
        },
      }
    );
  };

  let hostname;
  if (typeof window === "object") {
    hostname =
      window.location.hostname === "localhost"
        ? `http://localhost:${window.location.port}`
        : `${window.location.origin}`;
  } else {
    hostname = "https://www.tgscolorado.shop";
  }

  const apiPath = `/page-data/___api/store-specials`;
  const currentStore = locationsByStoreSlug[thisStoreId];
  const path = `${hostname}${apiPath}/${currentStore?.content?.id}/page-data.json`;

  useEffect(() => {
    if (path && !apiData && !specialsData)
      fetch(path)
        .then((response) => {
          response.json().then((data) => {
            const apiData = data.result.pageContext?.content?.apiData;
            if (
              apiData?.specials &&
              Object.keys(apiData?.specials).length > 0
            ) {
              setApiData(apiData);

              const accumulator = {};
              if (apiData?.specials?.med?.data?.specials) {
                accumulator["med"] = apiData?.specials?.med?.data?.specials;
              }
              if (apiData?.specials?.rec) {
                accumulator["rec"] = apiData?.specials?.rec?.data?.specials;
              }
              if (Object.keys(accumulator).length > 0) {
                setSpecialsData(accumulator);
              }
            } else {
              console.log("no API data found!");
              setApiData(null);
            }
          });
        })
        .catch((err) => {
          setApiError(err);
        });
  }, [path]);

  useEffect(() => {
    // Set the timezone offset for US Mountain Time (GMT-7)
    moment.updateLocale("en", {
      // Use a fixed offset of -7 hours for Mountain Time (GMT-7)
      // You may need to adjust this offset based on DST (Daylight Saving Time)
      // This is a simplified approach and may not accurately handle all cases
      // For more accurate timezone handling, consider using a dedicated library like moment-timezone
      utcOffset: -7 * 60,
    });
  }, []);

  const specialsPanels = [];

  let specialTitle;
  const parse = queryString.parse(props.location.search);

  const isSpecialIdPage = !!parse.special_id;

  if (typeof window === "undefined") {
    return null;
  }

  if (!apiData) {
    return <Spinner />;
  }

  /** 
   *  If you want to show all discount title without checking for products then remove this condition
   (!!item?.conditions?.product?.brands?.length ||
        !!item?.conditions?.product?.included_product_ids?.length)
  */
  const specialsDataFiltered = specialsData?.rec?.filter(
    (item) =>
      !!item.id &&
      isSpecialsBrandsOrProductsAvailable(item.conditions, item.special_type) &&
      isActiveDateRange(item.start_date, item.end_date) &&
      item.enabled
  );
  const getSpecialTitle = (parsed, specialsDataFiltered, currentStore) => {
    const specialIdFromQuery = parseInt(parsed.special_id);
    let showSpecialTitleHere = "";

    if (
      !isNaN(specialIdFromQuery) &&
      specialsDataFiltered &&
      specialsDataFiltered.length > 0
    ) {
      const special = specialsDataFiltered.find(
        (special) => special.id === specialIdFromQuery
      );
      if (special) {
        showSpecialTitleHere = special.title;
      }
    }

    return showSpecialTitleHere
      ? showSpecialTitleHere
      : `Specials for ${currentStore?.content?.nickname}`;
  };

  return (
    <div className={styles.special__container}>
      <div className={styles.item__top_buttons}>
        <a
          href={`/shop/${currentStore?.content?.id}`}
          style={{
            position: "absolute",
            width: "1px",
            height: "1px",
            padding: "0",
            margin: "-1px",
            overflow: "hidden",
            clip: "rect(0, 0, 0, 0)",
            border: "0",
            textDecoration: "none",
            color: "transparent",
          }}
        >
          The Green Solution Menu
        </a>
        <div className={styles.special__header}>
          <h1>{getSpecialTitle(parsed, specialsDataFiltered, currentStore)}</h1>

          <div className={styles.heroBtnContainer}>
            {!!parsed.special_id && (
              <Button
                theme="tgsButtonHero"
                label="View All Specials"
                id={`specials-page-view-all-specials`}
                onClick={(e) => {
                  navigate(`/shop/${thisStore?.content?.id}/specials`);
                  posthog &&
                    console.info(
                      `Posthog event -> ${posthogEvents.SPECIALS_LINK_CLICKED}:`,
                      "View All Specials",
                      "\n",
                      `To ${`/shop/${thisStore?.content?.id}/specials`}`,
                      "\n",
                      `From ${window.location.pathname}`
                    );
                  posthog?.capture(posthogEvents.SPECIALS_LINK_CLICKED, {
                    $EventDetails: {
                      type: "View All Specials",
                      to: `/shop/${thisStore?.content?.id}/specials`,
                      from: window.location.pathname,
                    },
                  });
                }}
              />
            )}

            <Button
              theme={
                !!parsed.special_id ? "tgsTransparentGreen" : "tgsButtonHero"
              }
              label={`Shop ${currentStore?.content?.nickname}`}
              id={`specials-page-shop`}
              onClick={(e) => {
                if (thisStore?.content?.id === cartData.store.id) {
                  navigate(`/shop/${thisStore?.content?.id}`);
                  posthog &&
                    console.info(
                      `Posthog event -> ${posthogEvents.LOCATION_LINK_CLICKED}:`,
                      `Shop ${currentStore?.title}`,
                      "\n",
                      `To ${`/shop/${thisStore?.content?.id}`}`,
                      "\n",
                      `From ${window.location.pathname}`
                    );
                  posthog?.capture(posthogEvents.LOCATION_LINK_CLICKED, {
                    $EventDetails: {
                      type: `Shop ${currentStore?.title}`,
                      to: `/shop/${thisStore?.content?.id}`,
                      from: window.location.pathname,
                    },
                  });
                } else if (cartData.store.id === null) {
                  cartData.store.id = locationData.location.location_id;
                  cartData.store.name = locationData.title;
                  cartData.store.janeId =
                    locationData.location.vendor_integrations.jane_rec_id;
                  cartData.products = [];
                  cartData.productsCount = 0;
                  actions.session.doSetCartData(cartData);
                } else {
                  setShowLocationUpdateModal(locationData.location);
                }
              }}
            />
          </div>
        </div>

        {!!specialsDataFiltered?.length && !parse.special_id && (
          <div
            id="deals-container"
            className={styles.deals_container}
            onClick={toggleClass}
          >
            <div className={styles.deals_header_container}>
              <h3>STOREWIDE SPECIALS</h3>
              <button
                id="specials-deals-toggle"
                className={
                  !isActive ? `${styles.active}` : `${styles.inactive}`
                }
              />
            </div>
            <div
              className={
                isActive ? `${styles.deals_info}` : `${styles.deals_info_open}`
              }
            >
              <ul>
                {specialsDataFiltered?.map((val) => {
                  // if (
                  //   !!val.id &&
                  //   (!!val?.conditions?.product?.brands?.length ||
                  //     !!val?.conditions?.product?.included_product_ids
                  //       ?.length)
                  // ) {
                  //   return null;
                  // }
                  return <li key={getSlug(val.title)}>{val.title}</li>;
                })}
              </ul>
            </div>
          </div>
        )}

        {!!specialsDataFiltered?.length ? (
          <div>
            <SpecialsResults
              specialsData={specialsDataFiltered}
              parsed={parsed}
              janeAlgoliaIndex={janeAlgoliaIndex}
              specialTitle={specialTitle}
              isSpecialIdPage={isSpecialIdPage}
              searchClient={searchClient}
              actions={actions}
              setShowCartModal={setShowCartModal}
              setShowLocationUpdateModal={setShowLocationUpdateModal}
              currentStore={currentStore}
              location={location}
              cartData={cartData}
              posthog={posthog}
            />
          </div>
        ) : (
          <p className={styles.no_result__desc}>
            {!!parsed.special_id
              ? `No special with ID ${parsed.special_id} was found.`
              : " Currently no specials at this location."}
          </p>
        )}
      </div>

      <Modal
        buttonOpts={["tgssmall", "tgsTransparent"]}
        modalTitle={`Cart`}
        variant={`cart`}
        dismissModal={() => {
          setShowCartModal(false);
          console.info(
            `Posthog event -> ${posthogEvents.ADD_TO_CART_MODAL_CLOSED}`
          );
          posthog?.capture(posthogEvents.ADD_TO_CART_MODAL_CLOSED, {
            type: "cart",
            status: "closed",
          });
        }}
        extraClasses="modal-dialog-cart"
        hide={!showCartModal}
        currentStore={currentStore}
        shouldCloseOnOverlayClick={true}
        actions={actions}
        user={currentUser}
        closeTimeoutMS={100}
        posthog={posthog}
      />
      <Modal
        buttonOpts={["tgssmall", "tgsTransparent"]}
        modalTitle={`Change Locations?`}
        variant={`location_update`}
        dismissModal={() => {
          setShowLocationUpdateModal(false);
        }}
        extraClasses="modal-dialog-location-update"
        hide={!showLocationUpdateModal}
        currentStore={currentStore}
        shouldCloseOnOverlayClick={false}
        // showSpecialModal={showSpecialModal}
        actions={actions}
        location={showLocationUpdateModal}
        posthog={posthog}
      />
    </div>
  );
}

const SpecialsResults = memo(
  ({
    specialsData,
    parsed,
    specialTitle,
    isSpecialIdPage,
    searchClient,
    janeAlgoliaIndex,
    actions,
    setShowCartModal,
    currentStore,
    location,
    cartData,
    setShowLocationUpdateModal,
    posthog,
  }) => {
    const specialsPanels = [];
    specialsData !== null &&
      specialsData?.reduce((a, v) => {
        if (v.enabled) {
          if (
            v.discount_type === "bundle" ||
            v.discount_type === "percent" ||
            v.discount_type === "dollar_amount"
          ) {
            const special = v.conditions[v.special_type];

            if (special) {
              const productIds =
                v.special_type === "bundle"
                  ? special.dependent.included_product_ids
                  : special.included_product_ids;

              const hasSpecialBrandOrProduct =
                !!productIds?.length || !!special?.brands?.length;

              if (
                hasSpecialBrandOrProduct &&
                (!parsed.special_id ||
                  (parsed.special_id && parsed.special_id == v.id))
              ) {
                specialTitle = v.title;
                a.push(
                  <div
                    key={v.id}
                    className={
                      !isSpecialIdPage
                        ? `${styles.special_container_block}`
                        : null
                    }
                  >
                    <ProductsPanel
                      productIds={productIds}
                      specialsProductIds={productIds}
                      productBrands={special?.brands}
                      searchClient={searchClient}
                      indexName={janeAlgoliaIndex}
                      actions={actions}
                      setShowCartModal={setShowCartModal}
                      setShowLocationUpdateModal={setShowLocationUpdateModal}
                      isResponsive={false}
                      hasMixMatch={v.special_type === "bundle"}
                      bundleTitle={v.title}
                      bundleDescription={v.description}
                      bundlePercent={v.discount_percent}
                      bundleDollarAmount={v.discount_dollar_amount}
                      specialId={parsed.special_id}
                      viewAllLink={`/shop/${currentStore?.content?.id}/specials?special_id=${v?.id}`}
                      isSpecialIdPage={isSpecialIdPage}
                      currentStore={currentStore}
                      location={location}
                      cartData={cartData}
                      posthog={posthog}
                    />
                  </div>
                );
              }
            }
          }
        }

        return a;
      }, specialsPanels);
    return specialsPanels;
  }
);
